// External imports
import React, { useEffect, useRef } from 'react';
import NumberFormat from 'react-number-format';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import InfoIcon from '@material-ui/icons/Info';
import {
  AppBar,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Input,
  MenuItem,
  NativeSelect,
  ListItemText,
  Paper,
  Select,
  Toolbar,
  Typography,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import { formatUsername } from '~/app/Utility/general';
// Internal imports
import {
  populatePreparerField,
  getAssociatedOffices,
  populateAssociatedOfficesState,
} from './loginSettingsModalHelper.js';
import { useSetState } from '~/app/Utility/customHooks';
import WebHelpers, { statusOK } from '~/app/webHelpers.js';
import XlinkAPI from '~/app/api/xlinkAPI';
import ErrorHelpers from '~/app/errorHelpers.js';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import AuthAPI from '~/app/api/authAPI.js';
import AccessControl from '#/Auth/AccessControl.jsx';
import {
  HIERARCHY_TYPE,
  REQUIRED_SETUP_PAGES,
  VALID_EMAIL_REGEX,
  ADDRESS_DATA_STRINGS,
  NO_MULTI_OFFICE_ACCESS,
  REQUIRED_LINKED_PREP_ACCESS_LEVELS,
} from '~/app/constants.js';
// Redux imports
import { actions as appActions } from '~/app/redux/modules/app';
import { actions as loginSetupActions } from '~/app/redux/loginSetup/duck';
import { actions as drilldownActions } from '~/app/redux/drilldown/duck';
import { useSelector, useDispatch } from 'react-redux';
// Styling imports
import { styles } from '~/app/Components/Settings/Setup/Modals/css/loginSettingsModal.js';
import '~/app/Components/Settings/Setup/Modals/css/loginSettingsModal.css';

/**
 * This Modal displays the login information that the user can change for a selected user
 *
 * @category Setup
 * @component LoginSettingsModal
 */
const LoginSettingsModal = props => {
  const dispatch = useDispatch();
  const { classes } = props;
  const {
    currentView,
    linkablePreparers,
    editableLogin,
    isEditing,
    currentOffice,
    allAccessRoles,
  } = useSelector(state => ({
    currentView: state.drilldown.drilldownHistory[state.drilldown.drilldownHistory.length - 1],
    linkablePreparers: state.loginSetup.linkablePreparers,
    editableLogin: state.loginSetup.currentEditLogin,
    isEditing: state.loginSetup.isEditingLogin,
    currentOffice: state.officeProfile,
    allAccessRoles: state.loginSetup.allAccessRoles,
  }));

  const [state, setState] = useSetState({
    loginID: '',
    officeLoginID: '',
    displayName: '',
    emailAddress: '',
    confirmedEmailAddress: '',
    emailAddressUpdated: '',
    confirmedEmailAddressUpdated: '',
    cellPhone: '',
    accessLevel: 'default',
    accessRoleSelected: {},
    officeGroup: 'default',
    preparerShortcutID: 'default',
    preparerShortcutName: 'default',
    clientLetter: 'default',
    bankIDCode: '',
    trainingReturns: false,
    showFees: false,
    displayInvoice: false,
    hideWorkProgress: false,
    colorScheme: 'default',
    clientDataScreen: 'default',
    questionnaire: 'default',
    signaturePadType: 'default',
    returnCreationMethod: 'default',
    alternateReturnColors: false,
    loginType: 'default',
    loginStatus: 'default',
    isShowNoPreparersDialogOpen: false,
    isUpdatingEmailAddress: false,
    isShowEmailSentModalOpen: false,
    isShowEmailUpdatedSuccessfullyDlgOpen: false,
    currentEmailAddress: '',
    editingOwnLogin: false,
    accessLevelList: [],
    associatedOffices: [],
    isConfirmCancelOpen: false,
    canAccessRoleAssociateWithOffices: false,
    shouldRequirePreparer: false,
    disableLoginSave: false,
  });
  const initialFormRef = useRef({});

  useEffect(() => {
    dispatch(loginSetupActions.fetchLinkablePreparers(currentView?.loginID));

    const loginType = parseInt(editableLogin.hierarchy_type_id) || 'default';
    const cellPhone = editableLogin.cell_phone ? editableLogin.cell_phone : '';
    const trainingReturns =
      editableLogin.training_returns_only === undefined
        ? false
        : editableLogin.training_returns_only;
    const editingOwnLogin = currentView.loginID === editableLogin.login_id;
    const { preparerID, name } = populatePreparerField(
      editableLogin.preparer_id,
      linkablePreparers,
    );
    // TODO --> We Should maybe just have a login object as the state and edit and pass that

    setState({
      loginID: editableLogin.login_name || '',
      displayName: editableLogin.display_name || '',
      emailAddress: editableLogin.email || '',
      confirmedEmailAddress: editableLogin.email || '',
      loginStatus: editableLogin.status || 'default',
      accessLevel: editableLogin.access_level || 'default',
      editingOwnLogin: editingOwnLogin,
      cellPhone: cellPhone,
      trainingReturns: trainingReturns,
      loginType: loginType,
      preparerShortcutID: preparerID,
      preparerShortcutName: name,
      associatedOffices: populateAssociatedOfficesState(
        editableLogin || [],
        currentView,
        currentOffice,
      ),
    });

    // set Ref to Object containing the state to then check validation if canceling form
    initialFormRef.current = {
      loginID: editableLogin.login_name || '',
      displayName: editableLogin.display_name || '',
      emailAddress: editableLogin.email || '',
      confirmedEmailAddress: editableLogin.email || '',
      loginStatus: editableLogin.status || 'default',
      accessLevel: editableLogin.access_level || 'default',
      editingOwnLogin: editingOwnLogin,
      cellPhone: cellPhone,
      trainingReturns: trainingReturns,
      loginType: loginType,
      preparerShortcutID: preparerID,
      preparerShortcutName: name,
      associatedOffices: state.associatedOffices,
    };

    fetchAccessRoles();
  }, []);

  useEffect(() => {
    if (state.accessLevelList) {
      state.accessLevelList.forEach(accessLevel => {
        fetchAccessLevelsForRole(
          accessLevel.id,
          accessLevel.is_custom_role,
          accessLevel.access_role_name,
        );
      });
    }
  }, [state.accessLevelList]);

  useEffect(() => {
    const { preparerID, name } = populatePreparerField(
      editableLogin.preparer_id,
      linkablePreparers,
    );

    setState({
      preparerShortcutID: preparerID,
      preparerShortcutName: name,
    });
  }, [editableLogin, linkablePreparers]);

  useEffect(() => {
    if (state.accessLevel && state.accessLevel !== 'default') {
      const accessRole = allAccessRoles[state.accessLevel]?.find(
        accessLevel => accessLevel.id === NO_MULTI_OFFICE_ACCESS,
      );
      // if active is true, this means create login is an active access level in the selected role
      setState({ canAccessRoleAssociateWithOffices: !accessRole?.active });
    }

    // Checking to see if an access role has been selected
    if (allAccessRoles?.[state.accessLevel]) {
      const accessLevels = allAccessRoles[state.accessLevel];
      const shouldRequire = accessLevels.some(
        accessLevel =>
          // Checking for Access Levels that require a Preparer, to determine if the login should be linked to a preparer
          REQUIRED_LINKED_PREP_ACCESS_LEVELS.includes(accessLevel.id) && accessLevel.active,
      );

      setState({ shouldRequirePreparer: shouldRequire });
    }
  }, [state.accessLevel]);

  useEffect(() => {
    const notEFINOrPrep =
      currentView.role !== HIERARCHY_TYPE.PREPARER && currentView.role !== HIERARCHY_TYPE.EFIN;
    if (
      (state.shouldRequirePreparer &&
        state.preparerShortcutID !== 'default' &&
        state.preparerShortcutID) ||
      !state.shouldRequirePreparer ||
      notEFINOrPrep
    ) {
      setState({ disableLoginSave: false });
    } else {
      setState({ disableLoginSave: true });
    }
  }, [state.shouldRequirePreparer, state.preparerShortcutID]);

  /** Fetches access roles using the edited users access_level and sets level of the list and selected role */
  const fetchAccessRoles = async () => {
    // check if tech account or superuser to make to avoid temp techs making new tech accounts here
    if (currentView.role !== 50 && currentView.role !== 1) {
      try {
        const res = await XlinkAPI.fetchAccessRoles();
        if (statusOK(res)) {
          let accessRoleSelected;
          if (editableLogin.access_level) {
            accessRoleSelected = res.data.find(role => {
              return role.access_role_name === editableLogin.access_level;
            });
          }
          if (!accessRoleSelected) {
            accessRoleSelected = {};
          }
          let accessLevels = res?.data || [];

          if (currentView.role < 40) {
            // Filters out access roles Non-transmit and Reception as well as Preparer if hierarchy type < 40
            accessLevels = accessLevels.filter(
              accessRoleInfo =>
                accessRoleInfo.id !== 2 &&
                accessRoleInfo.id !== 14 &&
                accessRoleInfo.access_role_name !== 'Preparer',
            );
          }
          setState({
            accessLevelList: accessLevels,
            accessRoleSelected: accessRoleSelected,
          });
        }
      } catch (err) {
        ErrorHelpers.handleError('Error fetching Access Roles', err);
      }
    }
  };

  /**
   * Handles fetching the access levels for the Role
   *
   * @param {number} roleID role id is used to find the access levels tied to the role
   * @param {boolean} isCustom we check two tables in the api to retrieve all roles
   * @param {string} accessRoleName used to help parse
   */
  const fetchAccessLevelsForRole = async (roleID, isCustom, accessRoleName) => {
    try {
      const res = await XlinkAPI.fetchAccessLevelsForRole(roleID, isCustom);

      if (!Array.isArray(res.data)) {
        throw ErrorHelpers.createSimpleError(
          'Error updating Access Role. Return value is not properly formatted',
        );
      }
      dispatch(loginSetupActions.setAccessLevelRole(accessRoleName, res.data));
    } catch (err) {
      ErrorHelpers.handleError('Error updating Access Role', err);
    }
  };

  /**
   * Handles changes for the dynamic dropdown jsx
   *
   * @param {string} value value that the user selected
   * @param {string} name name of the value selected
   */
  const handleDropdownChange = (value, name) => {
    if (name === 'access_level') {
      const val = value === 'default' ? {} : JSON.parse(value);
      setState({
        accessRoleSelected: val,
        accessLevel: val.access_role_name,
      });
    } else if (name === ADDRESS_DATA_STRINGS.PREPARER_SHORTCUT_ID_STRING) {
      // value comes back as either an empty space " " or a string ID number
      if (value === 'default') {
        setState({
          preparerShortcutID: 'default',
          preparerShortcutName: 'default',
        });
        return;
      }
      const id = parseInt(value);
      const linkedPrep = linkablePreparers.find(prep => {
        if (prep.preparerID === id) {
          return prep;
        }
        return null;
      });

      const { preparerID, name } = linkedPrep;
      setState({
        preparerShortcutID: preparerID,
        preparerShortcutName: name,
      });
    } else {
      setState({ [name]: value });
    }
  };
  /**
   * Handles changes the user makes on inputs
   *
   * @param {event} e event dom for the input being edited by the user
   * @returns blank return - only changes state
   */
  // Handle onChange without validation for input fields
  const handleInputChange = e => {
    const target = e.target;

    if (target.name === ADDRESS_DATA_STRINGS.LOGIN_ID_STRING) {
      if (target.value.includes(' ')) {
        // don't allow space
        return;
      }
    }
    setState({ [target.name]: target.value });
  };

  /**
   * Handles the changes for the cell phone input.
   *
   * @param {Object} e - event which takes place in the DOM.
   */
  const handleCellPhoneChange = e => {
    const { value } = e.target;

    setState({ cellPhone: value.replace(/\D/g, '') });
  };

  /**
   * For emailing code to the user being edited
   *
   * @param {string} username the selected users username to use for the API call to send email
   */
  const resetPassword = async username => {
    username = username.toLowerCase();
    try {
      const res = await AuthAPI.sendCodeToEmail(username);
      if (statusOK(res)) {
        setState({
          isShowEmailSentModalOpen: true,
        });
      }
    } catch (err) {
      ErrorHelpers.handleError('Unable to reset password', err);
    }
  };

  /** Handles setting state to false closing the modal */
  const hideEmailSentModal = () => {
    setState({
      isShowEmailSentModalOpen: false,
    });
  };

  /**
   * Handles changes to a checkbox input
   *
   * @param {event} event event dom from checkbox that the user edited
   */
  const handleCheckbox = e => {
    setState({ [e.target.name]: e.target.checked });
  };

  /**
   * Removes leading and trailing spaces
   * used on inputs "onBlur" property to run function when user edits then clicks off input
   *
   * @param {event} e event dom used for retrieving the value and name from the input
   */
  const removeExtraSpaces = e => {
    const name = e.target.name;
    let value = e.target.value;
    value = value.replace(/\s\s+/g, ' '); // Replace tabs, new tabs and multiple spacing with single blank space
    const valTrim = value.trim();
    setState({ [name]: valTrim });
  };

  /**
   * Handles validation for inputs that no special characters are wanted
   *
   * @param {event} e event dom used for retrieving the value and name from the input
   */
  const handleInputValidationNoSpecialChars = e => {
    let value = e.target.value;
    const name = e.target.name;
    if (name === ADDRESS_DATA_STRINGS.LOGIN_ID_STRING) {
      // For login ID/shortcut input field only allow one word, so no blank spaces allowed
      // Valid charcters are alphanumeric, hyphen and underscore
      value = formatUsername(value);
      if (
        name === ADDRESS_DATA_STRINGS.CITY_STRING ||
        name === ADDRESS_DATA_STRINGS.OVERRIDE_CITY_STRING
      ) {
        value = value.replace(/[0-9]/g, ''); // Remove digits[0-9]
      }
    }
    setState({ [name]: value });
  };

  /**
   * Dynamic menu that changes based on props
   *
   * @param {Object} contents entire object used for mapping out ui
   * @param {string} name the name of the input
   * @param {number} value the preparers ID
   * @param {undefined} ID undefined used on NativeSelect material-ui comp
   * @param {boolean} disabled used for disabling dropdown
   * @returns {jsx} renders dropdown input
   */
  const renderDropdown = (contents, name, value, ID, disabled = false) => {
    // MenuItem Style
    const nameAndID = name;
    let passValue = value;
    const passID = ID;
    const isImplemented = contents && contents.length > 0;
    let showRedBorder = false;
    if (name === 'preparerShortcutID') {
      if (
        (contents?.length === 1 &&
          contents?.[0].preparerID < 1 &&
          (state.loginType === 41 || state.shouldRequirePreparer) &&
          (state.preparerShortcutID === 'default' || state.preparerShortcutID === 0)) ||
        passValue === 'default'
      ) {
        showRedBorder = true;
      }

      if (contents?.length === 1 && contents?.[0].preparerID > 0 && passValue !== 'default') {
        passValue = contents?.[0].preparerID;
      }
    } else if (name === 'access_level' && value) {
      const objLen = Object.entries(value).length;
      value = JSON.stringify(value);
      passValue = value;
      showRedBorder = value === undefined || objLen === 0;
    } else {
      showRedBorder = !!(isImplemented && passValue === 'default');
    }

    /**
     * Closure function
     */
    function dropdownOptions() {
      return contents && contents.length > 0
        ? contents.map((entry, index) => {
            let keyVal = entry;
            const selected = '';
            let optionVal = entry;
            let optionText = entry;
            let shouldDisable = false;
            let optionClassName = classes.menuEntryStyle;

            if (typeof entry === 'object') {
              if (name === ADDRESS_DATA_STRINGS.PREPARER_SHORTCUT_ID_STRING) {
                keyVal = entry.preparerID;
                optionVal = entry.preparerID;
                optionText = entry.name;

                if (passValue === 'default') {
                  passValue = optionVal;
                }
              } else if (name === ADDRESS_DATA_STRINGS.LOGIN_TYPE_STRING) {
                keyVal = entry.login_type_id;
                optionVal = entry.login_type_id;
                optionText = entry.login_type_name;
              } else if (name === ADDRESS_DATA_STRINGS.LOGIN_STATUS_STRING) {
                keyVal = entry.type;
                optionVal = entry.type;
                optionText = entry.display;
              } else {
                const accessRole = allAccessRoles[entry.access_role_name]?.find(
                  accessLevel => accessLevel.id === NO_MULTI_OFFICE_ACCESS,
                );
                shouldDisable =
                  (accessRole?.active && state.associatedOffices?.length > 1) ||
                  (state.associatedOffices?.length > 1 &&
                    state.accessRoleSelected.access_role_name === 'Preparer');

                keyVal = index + 'access_level';
                optionVal = JSON.stringify(entry);
                optionText = entry.access_role_name;
                optionClassName += shouldDisable ? classes.menuEntryDisabled : '';
              }
            }

            return (
              <option
                key={keyVal}
                value={optionVal}
                className={optionClassName}
                disabled={shouldDisable}
                selected={selected}
              >
                {optionText}
              </option>
            );
          })
        : [];
    }
    return (
      <NativeSelect
        value={passValue}
        onChange={e => handleDropdownChange(e.target.value, e.target.name)}
        disableUnderline
        name={nameAndID}
        disabled={disabled || !isImplemented}
        id={passID}
        className={classes.nativeSelect}
        error={showRedBorder}
      >
        <option value="default" className={classes.menuItemStyle}>
          {''}
        </option>
        {dropdownOptions()}
      </NativeSelect>
    );
  };

  /**
   * Handles state on displaying Modal for confirming when user cancels edited info on form
   *
   * @param {boolean} toggle boolean used to close modal
   */
  const toggleConfirmCancel = toggle => {
    // this toggle is what we check for to show the 'Lose Changes' modal
    const hasChanged = cancelFormValidation();
    if (!hasChanged) {
      setState({
        isConfirmCancelOpen: toggle,
      });
    } else {
      props.onCloseLoginModal(false);
    }
  };

  /**
   * Handles setting the state for the current email address being updated
   * and setting the modal boolean to true, displaying the modal
   * */
  const switchFormToUpdateEmail = () => {
    // Record the current email address for error checking
    setState({ currentEmailAddress: state.emailAddress, isUpdatingEmailAddress: true });
  };

  /** Toggles and resets update email modal and form */
  const toggleEditEmailModal = () => {
    setState({
      isUpdatingEmailAddress: !state.isUpdatingEmailAddress,
      emailAddressUpdated: '',
      confirmedEmailAddressUpdated: '',
    });
  };

  /** Submits updating email form to the api and displaying success/error dialog */
  const updateEmailAddress = async () => {
    // This is where all the work with updating the email address happens
    const req = {
      login_id: editableLogin.login_id,
      user_name: state.loginID,
      old_email: state.currentEmailAddress,
      new_email: state.emailAddressUpdated,
    };
    try {
      const res = await XlinkAPI.updateEmailAddress(req);
      if (statusOK(res, false)) {
        setState({ isShowEmailUpdatedSuccessfullyDlgOpen: true });
        toggleEditEmailModal();
      }
    } catch (err) {
      ErrorHelpers.handleError('Failed to update email address', err);
    }
  };

  /** Handles closing the updating email success dialog  */
  const closeEmailUpdatedSuccessfullyDlg = () => {
    // Close the dialog
    // Move user back to edit user login screen in case they have further changes
    setState({ isShowEmailUpdatedSuccessfullyDlgOpen: false, isUpdatingEmailAddress: false });
  };

  /**
   * Handles creating a login for a user OR updating a users login and form validation
   *
   * @returns no return
   */
  const handleLoginForm = async () => {
    const notEFINOrPrep =
      currentView.role !== HIERARCHY_TYPE.PREPARER || currentView.role !== HIERARCHY_TYPE.EFIN;
    const errorTitle = isEditing ? 'Failed to edit login' : 'Failed to create login';

    // For adding a new login
    if (!isEditing) {
      if (state.loginID === undefined || state.loginID === null) {
        ErrorHelpers.handleError(errorTitle, {
          response: {
            data: {
              error_message: 'Username can only contain alphanumeric characters',
              error_code: 0,
            },
          },
        });
        return;
      }
      if (!state.loginID.match(/^[0-9a-zA-Z-_]+$/)) {
        ErrorHelpers.handleError(errorTitle, {
          response: {
            data: {
              error_message: 'Username can only contain alphanumeric characters',
              error_code: 0,
            },
          },
        });
        return;
      }

      // make sure display name is not empty
      if (!state.displayName) {
        ErrorHelpers.handleError(errorTitle, {
          response: {
            data: {
              error_message: 'Display name must be entered',
              error_code: 0,
            },
          },
        });
        return;
      }

      // Make sure email is in email form
      if (!VALID_EMAIL_REGEX.test(state.emailAddress)) {
        ErrorHelpers.handleError(errorTitle, {
          response: {
            data: { error_message: 'Please enter a proper email', error_code: 0 },
          },
        });
        return;
      }

      if (state.emailAddress !== state.confirmedEmailAddress) {
        ErrorHelpers.handleError(errorTitle, {
          response: {
            data: { error_message: 'Email fields must match', error_code: 0 },
          },
        });
        return;
      }

      // Check to See if Login Type Was selected if the current view is an efin
      if (!notEFINOrPrep && state.loginType !== 41 && state.loginType !== 40) {
        ErrorHelpers.handleError(errorTitle, {
          response: {
            data: { error_message: 'Login Type must be selected', error_code: 0 },
          },
        });
        return;
      }
    }

    if (state.accessLevel === 'default') {
      ErrorHelpers.handleError(errorTitle, {
        response: {
          data: {
            error_message: 'Select an access level before continuing',
            error_code: 0,
          },
        },
      });
      return;
    }

    // checks to see if state is not a number ('default') and transforms to integer for req
    const linkedPreparer = isNaN(state.preparerShortcutID) ? 0 : state.preparerShortcutID;
    const loginType = parseInt(state.loginType) || 0;
    // Checks to see if user is creating or editing a Login
    if (!isEditing) {
      // Creating login
      const req = {
        loginID: currentView.loginID,
        identifier: state.loginID,
        displayName: state.displayName.trim(),
        email: state.emailAddress,
        confirmEmail: state.confirmedEmailAddress,
        cellPhone: state.cellPhone.toString().replace(/[^0-9]/g, ''),
        accessLevel: JSON.stringify(state.accessRoleSelected),
        linkedPreparer: linkedPreparer,
        loginType: loginType,
        trainingReturnsOnly: state.trainingReturns,
        associatedOffices: state.associatedOffices,
      };
      try {
        const res = await XlinkAPI.addNewLogin(req);

        if (statusOK(res, false)) {
          props.onCloseLoginModal();
          dispatch(loginSetupActions.fetchLogins(currentView.loginID));
        }
      } catch (error) {
        ErrorHelpers.handleError('Failed to create login', error);
      }
    } else {
      // Updating login
      const req = {
        loginID: currentView.loginID,
        identifier: editableLogin.login_id,
        displayName: state.displayName.trim(),
        email: state.emailAddress,
        confirmEmail: state.confirmedEmailAddress,
        cellPhone: state.cellPhone.toString().replace(/[^0-9]/g, ''),
        accessLevel: JSON.stringify(state.accessRoleSelected),
        linkedPreparer: linkedPreparer,
        loginStatus: parseInt(state.loginStatus),
        trainingReturnsOnly: state.trainingReturns,
        associatedOffices: state.associatedOffices,
        loginType: loginType,
      };
      try {
        const res = await XlinkAPI.editLogin(req);

        if (statusOK(res, false)) {
          props.onCloseLoginModal();
          dispatch(loginSetupActions.fetchLogins(currentView.loginID));
          getAccountLinkedtoPreparer();
          if (editableLogin?.login_id === currentView?.loginID) {
            dispatch(
              drilldownActions.modifyCurrentView({ ...currentView, preparerID: linkedPreparer }),
            );
          }
        }
      } catch (err) {
        ErrorHelpers.handleError('Failed to edit login', err);
      }
    }
  };

  /** Fetches account linked to preparer */
  const getAccountLinkedtoPreparer = async () => {
    const payload = WebHelpers.getJWTPayload();
    const roleGroup = payload.hierarchy_type_id;
    // Only offices and managers (type EFIN) should see the capture signature option
    if (roleGroup === HIERARCHY_TYPE.EFIN) {
      try {
        const res = await XlinkAPI.getAccountLinkedToPreparer();
        if (statusOK(res)) {
          dispatch(appActions.updateLinkToPreparerValue(res.data));
        }
      } catch (err) {
        ErrorHelpers.handleError('Error fetching Login Linked to Preparer', err);
      }
    }
  };

  /**
   * Generates items for a menu to be selected from a dropdown
   *
   * @returns {jsx} menu items (offices) for dropdown
   */
  const availableOfficeSelection = () => {
    let offices;
    if (props.availableOffices && state.associatedOffices) {
      offices = props.availableOffices.map((office, idx) => {
        return (
          <MenuItem
            key={'office' + idx}
            value={office.office_id}
            disabled={currentOffice.office_id === office.office_id}
          >
            <Checkbox
              checked={
                state.associatedOffices.indexOf(office.office_id) > -1 ||
                currentOffice.office_id === office.office_id
              }
              disabled={currentOffice.office_id === office.office_id}
            />
            <ListItemText primary={office.name + '(' + office.efin + ')'} />
          </MenuItem>
        );
      });
    }
    return offices;
  };

  /**
   * Handles checking if state and ref are the same, meaning no changes were made to the form ->
   * meaning the Confirm Cancel dialog that pops up, is not needed
   *
   * @returns {boolean} isEqual is returning a boolean if both objects are equal
   */
  const cancelFormValidation = () => {
    const objectOne = {
      loginID: state.loginID,
      displayName: state.displayName,
      emailAddress: state.emailAddress,
      confirmedEmailAddress: state.confirmedEmailAddress,
      loginStatus: state.loginStatus,
      accessLevel: state.accessLevel,
      editingOwnLogin: state.editingOwnLogin,
      cellPhone: state.cellPhone,
      trainingReturns: state.trainingReturns,
      loginType: state.loginType,
      preparerShortcutID: state.preparerShortcutID,
      preparerShortcutName: state.preparerShortcutName,
      associatedOffices: state.associatedOffices || [],
    };
    const isEqual = JSON.stringify(objectOne) === JSON.stringify(initialFormRef.current);
    return isEqual;
  };

  const notEFINOrPrep =
    currentView.role !== HIERARCHY_TYPE.PREPARER && currentView.role !== HIERARCHY_TYPE.EFIN;

  if (
    !notEFINOrPrep &&
    state.isShowNoPreparersDialogOpen &&
    state.loginType === 41 &&
    linkablePreparers &&
    linkablePreparers.length === 0
  ) {
    setState({ isShowNoPreparersDialogOpen: true });
  }

  const loginStatusList = [
    {
      type: 0,
      display: 'Inactive',
    },
    {
      type: 1,
      display: 'Active',
    },
  ];

  // TODO --> This should be temporary
  const loginTypeList = [
    {
      login_type_id: 40,
      login_type_name: 'Manager',
      forRole: [HIERARCHY_TYPE.EFIN],
    },
    {
      login_type_id: 41,
      login_type_name: 'Preparer',
      forRole: [HIERARCHY_TYPE.EFIN],
    },
  ];

  const editAddNewLogin = isEditing;
  const styleButton = editAddNewLogin ? styles.styleEditLogin : styles.styleNewLogin;

  const styleButtons = editAddNewLogin ? styles.styleButtonsEdit : styles.styleButtonsNew;

  // Check if all the required fields are valid before allowing the user to click on "Add" button
  // The required fields to create a new login under Setup are:
  // Username, Display Name, Email Address, Confirm Email Address, Access Level, and Login Type
  const validUsername = state.loginID !== undefined && state.loginID.length > 0;
  const validDisplayName = state.displayName !== undefined && state.displayName.length > 0;
  const validEmail = state.emailAddress !== undefined && VALID_EMAIL_REGEX.test(state.emailAddress);
  const validConfirmEmail =
    state.confirmedEmailAddress !== undefined &&
    VALID_EMAIL_REGEX.test(state.confirmedEmailAddress) &&
    state.confirmedEmailAddress === state.emailAddress;
  const validEmailUpdated =
    state.emailAddressUpdated !== undefined && VALID_EMAIL_REGEX.test(state.emailAddressUpdated);
  const validConfirmEmailUpdated =
    state.confirmedEmailAddressUpdated !== undefined &&
    VALID_EMAIL_REGEX.test(state.confirmedEmailAddressUpdated) &&
    state.confirmedEmailAddressUpdated === state.emailAddressUpdated;
  const validAccessLevel =
    state.accessLevel !== undefined &&
    state.accessLevel.length > 0 &&
    state.accessLevel !== 'default';
  const loginTypeID = parseInt(state.loginType);
  const validLoginType = loginTypeID && loginTypeID !== 'default';
  const validPreparer =
    (state.shouldRequirePreparer &&
      state.preparerShortcutID !== 'default' &&
      state.preparerShortcutID) ||
    !state.shouldRequirePreparer;
  // Make sure that they enter in a different email address when updating address
  const enteredNewEmail = state.emailAddressUpdated !== state.currentEmailAddress;

  const disableUpdateEmailButton = !(
    validEmailUpdated &&
    validConfirmEmailUpdated &&
    enteredNewEmail
  );

  let disableSubmitButton;
  if (notEFINOrPrep) {
    disableSubmitButton = !(
      validUsername &&
      validDisplayName &&
      validEmail &&
      validConfirmEmail &&
      validAccessLevel
    );
  } else {
    disableSubmitButton = !(
      validUsername &&
      validDisplayName &&
      validEmail &&
      validConfirmEmail &&
      validAccessLevel &&
      validLoginType &&
      validPreparer
    );
  }

  return (
    <div>
      <Paper>
        <AppBar position="static">
          <Toolbar className={classes.toolbarStyle}>
            <Typography className={classes.typographyStyle}>
              {isEditing ? 'Edit Login' : 'Add New Login'}
            </Typography>
            <Typography
              className={classes.typographyStyle}
              onClick={() => toggleConfirmCancel(true)}
            >
              X
            </Typography>
          </Toolbar>
        </AppBar>
        {!state.isUpdatingEmailAddress && (
          <form className="login-modal-form">
            <div className="login-modal-div-login-details">User Details</div>
            <Grid container spacing={8}>
              <Grid item xs={6}>
                <FormControl>
                  <InputLabel
                    className={classes.labelWidthStyle}
                    required={false}
                    htmlFor="txtLoginIDAddNewLogin"
                    shrink
                  >
                    Username
                  </InputLabel>
                  <Input
                    id="txtLoginIDAddNewLogin"
                    name={ADDRESS_DATA_STRINGS.LOGIN_ID_STRING}
                    value={state.loginID}
                    onChange={handleInputValidationNoSpecialChars}
                    className={classes.inputStyle}
                    disableUnderline
                    disabled={isEditing}
                    error={!!(state.loginID === undefined || state.loginID.length < 1)}
                    autoComplete="off"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl>
                  <InputLabel
                    className={classes.labelWidthStyle}
                    required={false}
                    htmlFor="txtLoginNameAddNewLogin"
                    shrink
                  >
                    Display Name
                  </InputLabel>
                  <Input
                    id="txtLoginNameAddNewLogin"
                    name="displayName"
                    value={state.displayName}
                    onChange={handleInputChange}
                    className={classes.inputStyle}
                    disableUnderline
                    error={!!(state.displayName === undefined || state.displayName.length < 1)}
                    autoComplete="off"
                    onBlur={removeExtraSpaces}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl>
                  <InputLabel
                    className={classes.labelWidthStyle}
                    required={false}
                    htmlFor="txtEmailAddressAddNewLogin"
                    shrink
                  >
                    Email Address
                  </InputLabel>
                  <Input
                    disabled={isEditing}
                    id="txtEmailAddressAddNewLogin"
                    name="emailAddress"
                    value={state.emailAddress}
                    onChange={handleInputChange}
                    className={classes.inputStyle}
                    disableUnderline
                    error={!VALID_EMAIL_REGEX.test(state.emailAddress)} // Email field that that must be in the following order: characters@characters.domain
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl>
                  <InputLabel
                    className={classes.labelWidthStyle}
                    required={false}
                    htmlFor="txtEmailAddressAddNewLogin"
                    shrink
                  >
                    Confirm Email Address
                  </InputLabel>
                  <Input
                    disabled={isEditing}
                    id="txtConfirmEmailAddressAddNewLogin"
                    name="confirmedEmailAddress"
                    value={state.confirmedEmailAddress}
                    onChange={handleInputChange}
                    className={classes.inputStyle}
                    disableUnderline
                    error={
                      !!(
                        state.confirmedEmailAddress === undefined ||
                        state.confirmedEmailAddress.length < 1 ||
                        state.confirmedEmailAddress !== state.emailAddress
                      )
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl>
                  <InputLabel
                    className={classes.labelWidthStyle}
                    required={false}
                    htmlFor="txtCellPhoneAddNewLogin"
                    shrink
                  >
                    Cell Phone
                  </InputLabel>
                  <NumberFormat
                    type="tel"
                    value={state.cellPhone}
                    name="cellPhone"
                    id="txtCellPhoneAddNewLogin"
                    isNumericString={true}
                    onChange={handleCellPhoneChange}
                    className={classes.inputStyle}
                    disableUnderline
                    format="(###) ###-####"
                    customInput={Input}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl>
                  <InputLabel
                    className={classes.labelWidthStyle}
                    htmlFor="ddlAccessLevelAddNewLogin"
                    shrink
                  >
                    Access Level
                    {state.associatedOffices?.length > 1 &&
                      state.accessRoleSelected.access_role_name === 'Preparer' && (
                        <Tooltip
                          title={
                            "The Access Level 'Preparer' is selected, and you are associated to more than one Office. To select another Access Level, unselect associated offices until you only have one."
                          }
                        >
                          <InfoIcon fontSize="small" />
                        </Tooltip>
                      )}
                  </InputLabel>
                  {renderDropdown(
                    state.accessLevelList,
                    'access_level',
                    state.accessRoleSelected,
                    'ddlAccessLevelAddNewLogin',
                    state.editingOwnLogin,
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl>
                  <InputLabel className={classes.labelWidthStyle} htmlFor="ddlloginStatus" shrink>
                    Login Status
                  </InputLabel>
                  {renderDropdown(
                    loginStatusList,
                    'loginStatus',
                    isEditing ? state.loginStatus : 1,
                    'ddlloginStatus',
                    !(
                      // Only enable if they are editing and have permisssions. Cant edit their own
                      (isEditing && !state.editingOwnLogin)
                    ),
                  )}
                  <FormControlLabel
                    label="Training Returns Only"
                    control={
                      <Checkbox
                        className={classes.styleCheckbox}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            className={classes.checkboxIcon}
                            color="secondary"
                          />
                        }
                        checkedIcon={
                          <CheckBoxIcon className={classes.checkboxIcon} color="primary" />
                        }
                        id="chkTrainingReturnsAddNewLogin"
                        name="trainingReturns"
                        checked={state.trainingReturns}
                        onChange={handleCheckbox}
                      />
                    }
                  />
                </FormControl>
              </Grid>
              {!notEFINOrPrep && (
                <Grid item xs={6}>
                  <FormControl>
                    <InputLabel
                      className={classes.labelWidthStyle}
                      htmlFor="txtPreparerShortcutIDAddNewLogin"
                      shrink
                    >
                      Link to Preparer
                    </InputLabel>
                    {renderDropdown(
                      linkablePreparers,
                      ADDRESS_DATA_STRINGS.PREPARER_SHORTCUT_ID_STRING,
                      state.preparerShortcutID,
                      'txtPreparerShortcutIDAddNewLogin',
                    )}
                    <AccessControl
                      requiredAction="write"
                      accessLevel="add/edit_preparer"
                      disableOnFalse={true}
                    >
                      <Button
                        color="primary"
                        style={styleButton}
                        onClick={() => props.loadSetupComponent(REQUIRED_SETUP_PAGES.PREPARER.NAME)}
                      >
                        Create New Preparer
                      </Button>
                    </AccessControl>
                    <AccessControl
                      requiredAction="write"
                      accessLevel="unlock_login"
                      disableOnFalse={true}
                    >
                      <Button
                        color="primary"
                        className={classes.styleButton}
                        onClick={() => resetPassword(state.loginID)}
                      >
                        Reset Login
                      </Button>
                    </AccessControl>
                  </FormControl>
                </Grid>
              )}
              {!notEFINOrPrep && (
                <Grid item xs={6}>
                  <FormControl>
                    <InputLabel
                      className={classes.labelWidthStyle}
                      htmlFor="txtLoginTypeView"
                      shrink
                    >
                      {' Login Type '}
                      <Tooltip title="The 'Manager' Login Type allows for a user to view all returns within an office/efin and is required to Capture Ero Signature. The 'Preparer' Login Type allows for a user to view all returns belonging to their assigned preparer.">
                        <InfoIcon fontSize="small" />
                      </Tooltip>
                    </InputLabel>
                    {renderDropdown(
                      loginTypeList,
                      ADDRESS_DATA_STRINGS.LOGIN_TYPE_STRING,
                      state.loginType,
                      'txtLoginTypeView',
                    )}
                  </FormControl>
                </Grid>
              )}
              {props.canAssociateOffice && !notEFINOrPrep && (
                <Grid item xs={6}>
                  <FormControl>
                    <InputLabel id="multiple-office-select-box-label">
                      Associated Offices
                      <Tooltip
                        title={`Only "Preparer" Access level logins are allowed to be associated with another office.`}
                      >
                        <InfoIcon fontSize="small" />
                      </Tooltip>
                    </InputLabel>

                    <Select
                      labelId="multiple-office-select-box-label"
                      id="multiple-office-select-box"
                      name="associatedOffices"
                      multiple
                      input={<Input className={classes.nativeSelect} />}
                      onChange={e =>
                        setState({
                          associatedOffices: e.target.value,
                        })
                      }
                      renderValue={getAssociatedOffices(
                        props.availableOffices,
                        props.availableOfficesMap,
                      )}
                      disabled={
                        !state.canAccessRoleAssociateWithOffices ||
                        state.accessRoleSelected.access_role_name !== 'Preparer'
                      } // prevent access level of 'create_login' from associating with multiple Offices
                      value={state.associatedOffices || []}
                    >
                      {availableOfficeSelection()}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
            <div style={styleButtons}>
              {isEditing && (
                <Button
                  id="btnUpdateEmailAddress"
                  color="primary"
                  onClick={switchFormToUpdateEmail}
                  className={classes.updateEmailButton}
                >
                  Update Email Address
                </Button>
              )}

              <span>
                <Button
                  id="btnCancelAddNewLogin"
                  color="primary"
                  onClick={() => toggleConfirmCancel(true)}
                  className={classes.cancelButton}
                >
                  Cancel
                </Button>
                <Button
                  id="btnAddAddNewLogin"
                  color="primary"
                  variant="contained"
                  className={classes.saveButton}
                  onClick={handleLoginForm}
                  disabled={disableSubmitButton && state.disableLoginSave}
                >
                  Save
                </Button>
              </span>
            </div>
            <br />
          </form>
        )}

        {/** They are updating the email address of the login */}
        {state.isUpdatingEmailAddress && (
          <form className="login-modal-form">
            <div className="login-modal-div-login-details">Update User Login Email Address</div>
            <Grid container spacing={8}>
              <Grid item xs={12} className={classes.largeItemPadding}>
                <div>
                  {`If you decide to update the email address for this user the user's account will
                  be deactivated. A new activation email will be sent to the updated email address
                  and the account will continue to be deactivated until user reactivates via
                  activation email. Password will be required to be updated as well during the
                  activation process.`}
                </div>
              </Grid>

              <Grid item xs={12} className={classes.largeItemPadding}>
                <span> Username: {state.loginID} </span>
              </Grid>
              <Grid container item xs={12} className={classes.largeItemPadding}>
                <Grid item xs={6}>
                  <FormControl>
                    <InputLabel
                      className={classes.labelWidthStyle}
                      required={true}
                      htmlFor="txtEmailAddressAddNewLogin"
                      shrink
                    >
                      Email Address
                    </InputLabel>
                    <Input
                      disabled={false}
                      id="txtEmailAddressUpdatedAddNewLogin"
                      name="emailAddressUpdated"
                      value={state.emailAddressUpdated}
                      placeholder={state.emailAddress}
                      onChange={handleInputChange}
                      className={classes.inputStyle}
                      disableUnderline
                      error={
                        !(
                          VALID_EMAIL_REGEX.test(state.emailAddressUpdated) &&
                          state.emailAddressUpdated !== state.currentEmailAddress
                        )
                      } // Email field that that must be in the following order: characters@characters.domain
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl>
                    <InputLabel
                      className={classes.labelWidthStyle}
                      required={false}
                      htmlFor="txtEmailAddressAddNewLogin"
                      shrink
                    >
                      Confirm Email Address
                    </InputLabel>
                    <Input
                      disabled={false}
                      id="txtConfirmEmailAddressUpdatedAddNewLogin"
                      name="confirmedEmailAddressUpdated"
                      value={state.confirmedEmailAddressUpdated}
                      placeholder={state.confirmedEmailAddress}
                      onChange={handleInputChange}
                      className={classes.inputStyle}
                      disableUnderline
                      error={
                        !!(
                          state.confirmedEmailAddressUpdated === undefined ||
                          state.confirmedEmailAddressUpdated.length < 1 ||
                          state.confirmedEmailAddressUpdated !== state.emailAddressUpdated
                        )
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid container item xs={12} className={classes.smallItemPadding}>
                  <Grid item xs={12}>
                    <div style={styleButtons}>
                      <span>
                        <Button
                          id="btnCancelUpdateEmailAddress"
                          color="primary"
                          onClick={toggleEditEmailModal}
                          className={classes.cancelButton}
                        >
                          Cancel
                        </Button>
                        <Button
                          id="btnUpdateEmailAddress"
                          color="primary"
                          variant="contained"
                          className={classes.updateEmailButton}
                          onClick={updateEmailAddress}
                          disabled={disableUpdateEmailButton}
                        >
                          Update Email Address
                        </Button>
                      </span>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      </Paper>
      <SimpleDialog
        open={state.isShowEmailSentModalOpen}
        onConfirm={hideEmailSentModal}
        dialogTitle="Email Sent"
        contentText="Login has been reset. Secure verifcation code has been emailed to user"
        cancelText="Close"
      />
      <SimpleDialog
        open={state.isShowEmailUpdatedSuccessfullyDlgOpen}
        onConfirm={closeEmailUpdatedSuccessfullyDlg}
        dialogTitle="Activation Email"
        contentText="Email Update Activation email has been sent to email box. Please follow through with
        reactivating the account."
      />
      <SimpleDialog
        open={state.isShowNoPreparersDialogOpen}
        onConfirm={() => setState({ isShowNoPreparersDialogOpen: false })}
        dialogTitle="Unable to modify login type"
        contentText="You can not edit the login type when drilled into a preparer. Edit the login type at the
        office level."
      />
      <SimpleDialog
        open={state.isConfirmCancelOpen}
        onClose={() => toggleConfirmCancel(false)}
        onConfirm={() => {
          toggleConfirmCancel(true);
          props.onCloseLoginModal();
        }}
        dialogTitle="Lose Unsaved Changes?"
        contentText="Are you sure you want to undo any pending changes?"
      />
    </div>
  );
};

export default withStyles(styles)(LoginSettingsModal);
